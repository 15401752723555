import PropTypes from 'prop-types'
import React from 'react'
import ProfileGif from '../images/yitaek.webp'
import Work from './Work'
import Travel from './Travel'

class Main extends React.Component {

  render() {

    const { isArticleVisible, setWrapperRef, ...props } = this.props

    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={ProfileGif} alt="" />
          </span>
          <p>
            Hi, I'm Yitaek. I'm a 30 year old, software engineer working at <a href="https://nydig.com">
              NYDIG</a> on the Bitcoin Custody team.  
          </p>
          <p>
            Besides writing code, I enjoy learning about new tech and breaking down complicated topics into simple terms. If you have an exciting 
            project or a product, feel free to reach out via the contact form. I'm always happy to explore ways to collaborate, whether it is writing tutorials, 
            featuring an interview on my blog, or holding deep-dive, consulting sessions on a regular basis. 
          </p>
          <p>
            Outside of work, I'm always planning for my next trip. I'm no fancy travel Instgram influencer, but I do love to
            explore new cultures, places, and try new foods around the world. I've also had my fair share of service trips and 
            voluntourism and would love to chat about that any time. 
          </p>
          <p>
            Finally, a fun fact: go Google <a href="https://lmgtfy.com/?q=duke+basketball+teletubby">Duke basketball teletubby</a>
          </p>
          {close}
        </article>

        <Work {...props} />
        <Travel {...props} />

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="contacts" method="post" data-netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="contacts" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/yitaekhwang/"
                className="icon fa-linkedin"
                target="_blank"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://medium.com/@yitaek" 
                className="icon fa-medium"
                target="_blank"
              >
                <span className="label">Medium</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/yitaek"
                className="icon fa-github"
                target="_blank"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/yitaek" 
                className="icon fa-instagram"
                target="_blank"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
