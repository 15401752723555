
import React from 'react'
import ReactMapboxGl, { Layer, Source, Marker, AttributionControl } from "react-mapbox-gl"
import * as S from './Travel.module.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'
import '../assets/css/mapbox.css'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let Map = false

if (typeof window !== `undefined`) {
  Map = ReactMapboxGl({
    accessToken: process.env.MAPBOX_TOKEN || "pk.eyJ1IjoieWl0YWVrIiwiYSI6ImNrNzlmZWg0bjBraGIzcW15Zm02bTVhMnYifQ.JubaB_2RD1YrK0oTVAfOiQ",
    attributionControl: true
  })
} 

const vectorSource = {
  "type": "vector",
  "url": 'mapbox://yitaek.djtcbab0'
}

const paintProps = {
  'fill-color': '#52489C', 
  'fill-opacity': 0.8, 
  'fill-outline-color': '#F2F2F2', 
}

const COUNTRY_LIST=[
  'USA', 'CAN', 'MEX', 'NIC', 'CYM', 'PER', 'TWN',
  'GBR', 'FRA', 'ESP', 'PRT', 'MAR', 'TZA', 'CHE', 'DEU', 'DNK', 'CHN',
  'SWE', 'NOR', 'FIN', 'RUS', 'HRV', 'TUR', 'AUT', 'HUN', 'KOR', 'JPN',
  'PHL', 'THA', 'MMR', 'IND', 'NPL', 'GRC', 'BIH', 'MNE', 'CRI', 'ISL',
  'ITA', 'VAT', 'CZE', 'BLZ', 'GTM', 'ARE', 'JAM', 'KNA', 'HKG', 'SGP',
  'JOR', 'ISR', 'ATG', 'ZAF', 'ZMB', 'ZWE', 'BWA', 'COL'
]


class Travel extends React.Component {
  constructor( props ) {
    super( props )
  }

  setupMap( map ) {
    this.map = map
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.article === "travel"){
      this.map.resize()
    }

    return true
  }

  render() {
   
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <article
        id="travel"
        className={`${this.props.article === 'travel' ? 'active' : ''} ${
          this.props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Travel</h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex'}} >
            <span className="icon fa-map-pin"></span>
            <p style={{ marginLeft: '5px' }}>Currently in NYC</p>
          </div>
          <div style={{ display: 'flex'}}>
            <span className="icon fa-plane"></span>
            <p style={{ marginLeft: '5px' }}>Next up: Qatar</p>
          </div>
        </div>
        <div style={{ width: '100%', height: '50vh'}}>
          {Map && <Map
            style="mapbox://styles/mapbox/streets-v9"
            className={S.mapContainer}
            center={[-77.0369, 38.9072]}
            zoom={[1]}
            onStyleLoad={( map ) => { this.setupMap( map ) }}
          >
            <Source id="countries" tileJsonSource={vectorSource} />
            <Layer 
              type="fill" 
              id="country-outline" 
              sourceId="countries" 
              paint={paintProps}
              sourceLayer="ne_10m_admin_0_countries-af67x2"
              before="country-label-sm"
              filter={['in', 'ADM0_A3_IS'].concat(COUNTRY_LIST)}
            />
            <Marker
              coordinates={[-73.935242, 40.730610]}
              anchor="bottom">
              <span className="icon fa-map-pin"></span>
            </Marker>
          </Map>}
        </div>
        {close}
      </article>
    )
  }


}

export default Travel