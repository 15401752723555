import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import * as S from './Work.module.css'
import LeveregeLogo from '../images/leverege-white.png'
import IfaLogo from '../images/ifa.png'
import DukeLogo from '../images/duke.png'
import AxoniLogo from '../images/axoni.png'
import NydigLogo from '../images/nydig.png'

class Work extends React.Component {

  constructor( props ) {
    super( props )
  }

  
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div>
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', width: 'fit-content' }}
        >
          <h2 className="major">Career</h2>

          <VerticalTimeline layout="1-column">
            <VerticalTimelineElement
              contentStyle={{ background: 'rgb(32,206,186)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(32,206,186)' }}
              date="Jun 2022 - present"
              iconStyle={{ background: '#fff', color: '#fff', borderColor: 'rgb(32,206,186)' }}
              icon={
                <div className={S.iconContainer}>
                  <img src={NydigLogo} style={{ height: '60%'}} />
                </div>
              }
            >
              <h3 className="vertical-timeline-element-title">Software Engineer</h3>
              <h4 className="vertical-timeline-element-subtitle">New York</h4>
              <p>
                Working on the custody team at NYDIG.
              </p>

            </VerticalTimelineElement>
            <VerticalTimelineElement
              contentStyle={{ background: 'rgb(4,172,240)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(4,172,240)' }}
              date="Oct 2020 - Jun 2022"
              iconStyle={{ background: '#fff', color: '#fff', borderColor: 'rgb(4,172,240)' }}
              icon={
                <div className={S.iconContainer}>
                  <img src={AxoniLogo} style={{ height: '60%'}} />
                </div>
              }
            >
              <h3 className="vertical-timeline-element-title">Sr. Software Engineer</h3>
              <h4 className="vertical-timeline-element-subtitle">New York</h4>
              <p>
                Responsible for the end to end management of all production and client facing 
                Axoni blockchain infrastructure for the capital markets industry. 
              </p>

            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentStyle={{ background: 'rgb(67,64,224)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(67,64,224)' }}
              date="Aug 2016 - Oct 2020"
              iconStyle={{ background: 'rgb(67,64,224)', color: '#fff' }}
              icon={
                <div className={S.iconContainer}>
                  <img src={LeveregeLogo} style={{ height: '60%'}} />
                </div>
              }
            >
              <h3 className="vertical-timeline-element-title">VP, Product Innovation</h3>
              <h4 className="vertical-timeline-element-subtitle">Washington, DC</h4>
              <p>
                Architected and implemented the overall infrastructure of the Leverege IoT Platform built on
                Docker and Kubernetes serving data from millions of sensors on AWS and GCP.
              </p>

            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentStyle={{ background: 'rgb(128,128,128)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid rgb(128,128,128)' }}
              date="Aug 2014 - May 2016"
              iconStyle={{ background: 'rgb(128,128,128)', color: '#fff' }}
              icon={
                <div className={S.iconContainer}>
                  <h3 className={S.iconText}>VF</h3>
                </div>
              }
            >
              <h3 className="vertical-timeline-element-title">CEO</h3>
              <h4 className="vertical-timeline-element-subtitle">Durham, NC</h4>
              <p>
                Designed and manufactured self-adjustable eyeglasses for the
                visually impaired in developing countries.
              </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              contentStyle={{ background: 'rgb(1,24,80)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid rgb(1,24,80)' }}
              date="Aug 2012 - May 2016"
              iconStyle={{ background: 'rgb(1,24,80)', color: '#fff' }}
              icon={
                <div className={S.iconContainer}>
                  <img src={DukeLogo} style={{ height: '100%'}} />
                </div>
              }
            >
              <h3 className="vertical-timeline-element-title">Duke University</h3>
              <h4 className="vertical-timeline-element-subtitle">Durham, NC</h4>
              <p>
                Graduated magna cum laude with B.S.E in Electrical/Computer & Biomedical Engineering
              </p>
            </VerticalTimelineElement>
            
          </VerticalTimeline>

          {close}
        </article>

       
      </div>
    )
  }
}

export default Work
